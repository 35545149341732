import { Data } from 'signup-constants/signupData/Data';
import { VerificationType } from '../app/VerificationType';
import { setVerificationType } from '../store/app/action-creators';
import { getAuthenticatedUser } from '../store/auth/selectors';
import { bulkUpdate } from '../store/signup-data/action-creators';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { clickCreateNewAccountSameUser, existingUser, redirectExistingUserAction } from '../store/auth/action-creators';
import { getExistingAccounts, getExistingAccountsError, isHubless } from '../store/existing-accounts/selectors';
import { isFlow } from '../utils/isFlow';
import { Flow } from 'signup-constants/Flow';
import { FlowStateManager } from '../flow-management/FlowStateManager';
import { Step } from 'signup-constants/Step';
import { getQueryParam } from '../routing/getQueryParam';
import { setNextStep } from '../flow-management/setNextStep';
import { NextStepManager } from '../flow-management/NextStepManager';
import { shouldSkipExistingAccountsStep } from '../store/existing-accounts/action-creators';
import { portalSelect } from '../step-existing-accounts/handlePortalSelect';
export const continueWithThisExistingUser = () => (dispatch, getState) => {
  const state = getState();
  const user = getAuthenticatedUser(state);
  const userIsHubless = isHubless(state);
  const accounts = getExistingAccounts(state);
  const existingAccountsError = getExistingAccountsError(state);
  dispatch(setVerificationType(VerificationType.ExistingUser));
  if (user) {
    dispatch(bulkUpdate({
      [Data.Email]: user.email,
      [Data.FirstName]: user.firstName,
      [Data.LastName]: user.lastName
    }));
  }
  const userOnlyFlow = getFlowConfig().isUserOnlyFlow;
  dispatch(existingUser());
  if (userOnlyFlow) {
    return dispatch(redirectExistingUserAction());
  }
  if (userIsHubless && !existingAccountsError) {
    if (isFlow(Flow.Academy) || ['connect'].includes(getQueryParam('hubs_signup-url'))) {
      dispatch(clickCreateNewAccountSameUser(true));
      return dispatch(FlowStateManager.removeSteps([Step.LandingPage, Step.Name, Step.Verification, Step.CodeVerification, Step.Password]));
    }
    return dispatch(setNextStep({
      rebuilder: NextStepManager.pushNextSteps(Step.HublessNoAccount)
    }));
  }
  if (shouldSkipExistingAccountsStep(accounts)) {
    return dispatch(portalSelect(accounts[0].id));
  }
  return dispatch(setNextStep({
    rebuilder: NextStepManager.pushNextSteps(Step.ExistingAccounts)
  }));
};