import { campaignAssistantRedirect, clipCreatorRedirect, landingPageCreatorRedirect, invoiceGeneratorRedirect, blogIdeasGeneratorMicroappRedirect, marketingEmailCreatorRedirect, guideCreatorRedirect } from '../intents/intentLinks';
import { getQueryParams } from '../routing/getQueryParams';
import { getHublet } from '../store/app/selectors';
import { getPortalId } from '../store/auth/selectors';
import { getInvoiceGeneratorState } from '../store/microapp/selectors';
export const getCampaignAssistantRedirect = state => {
  return campaignAssistantRedirect(getPortalId(state), getHublet(state), getQueryParams());
};
export const getBlogIdeasGeneratorRedirect = state => {
  return blogIdeasGeneratorMicroappRedirect(getPortalId(state), getHublet(state), getQueryParams());
};
export const getClipCreatorRedirect = state => {
  return clipCreatorRedirect(getPortalId(state), getHublet(state));
};
export const getGuideCreatorRedirect = state => {
  return guideCreatorRedirect(getPortalId(state), getHublet(state), getQueryParams());
};
export const getLandingPageCreatorRedirect = state => {
  return landingPageCreatorRedirect(getPortalId(state), getHublet(state), getQueryParams());
};
export const getInvoiceGeneratorRedirect = state => {
  const invoiceId = getInvoiceGeneratorState(state).invoiceId;
  return invoiceGeneratorRedirect(getPortalId(state), getHublet(state), Object.assign({}, getQueryParams(), {
    invoiceId: invoiceId ? invoiceId.toString() : ''
  }));
};
export const getMarketingEmailCreatorRedirect = state => {
  return marketingEmailCreatorRedirect(getPortalId(state), getHublet(state), getQueryParams());
};