import { Flow } from 'signup-constants/Flow';
import { getFlowKey } from '../routing/getFlowKey';
import { BranchCmsBusinessMaturity } from './branches/BranchCmsBusinessMaturity';
import { BranchIntegratedAppCookieless } from './branches/BranchIntegratedAppCookieless';
import { BranchOptionalPhoneNumber } from './branches/BranchOptionalPhoneNumber';
import { BranchQuestionFallback } from './branches/BranchQuestionFallback';
import { ProductSetupUIBranch } from './branches/ProductSetupUIBranch';
import { BranchAddHubletExistingUser } from './branches/BranchAddHubletStep';
import { BranchAddHubletNewUser } from './branches/BranchAddHubletNewUser';
import { BranchACQ0167 } from './branches/BranchACQ0167';
const DefaultBranches = [BranchQuestionFallback];
let FlowBranches;
export const getFlowBranches = () => {
  if (typeof FlowBranches === 'undefined') {
    const softwareSignupBranches = [...DefaultBranches, BranchOptionalPhoneNumber, BranchAddHubletExistingUser, BranchAddHubletNewUser];
    FlowBranches = {
      [Flow.Crm]: [...softwareSignupBranches, BranchACQ0167],
      [Flow.Marketing]: [...softwareSignupBranches, BranchACQ0167],
      [Flow.Sales]: [...softwareSignupBranches, ProductSetupUIBranch, BranchACQ0167],
      [Flow.Service]: [...softwareSignupBranches, BranchACQ0167],
      [Flow.CmsFree]: [BranchCmsBusinessMaturity, ...softwareSignupBranches],
      [Flow.Integrations]: [BranchIntegratedAppCookieless, BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.ClassroomTraining]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.AssetProvider]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.SolutionsProvider]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.FbCrm]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.HubspotForStartups]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.HubSpotPartners]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.Microapp]: [BranchCmsBusinessMaturity, BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.Commerce]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.Academy]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.AcademyEmbedded]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.DirectoryListing]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.Developers]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.CmsDevelopers]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.Trial]: [BranchAddHubletExistingUser, BranchAddHubletNewUser],
      [Flow.Purchase]: [BranchAddHubletExistingUser, BranchAddHubletNewUser]
    };
  }
  return FlowBranches[getFlowKey()] || DefaultBranches;
};