import { Flow } from 'signup-constants/Flow';
import { getFlowKey } from '../routing/getFlowKey';
import { EndpointsIntegrations } from '../integrations-flow/EndpointsIntegrations';
export const getFlowEndpointOverrides = (flowKey = getFlowKey()) => {
  switch (flowKey) {
    case Flow.Integrations:
      return EndpointsIntegrations;
    default:
      return {};
  }
};