import { Flow } from 'signup-constants/Flow';
import { Step } from 'signup-constants/Step';
import { MessageKey } from './MessageKey';
import { DesktopDefaults, MobileDefaults } from './MessagingDefaults';
import { Platform } from './Platform';
const DesktopMessaging = new Map([[Flow.Crm, new Map([...Array.from(DesktopDefaults.entries())])], [Flow.Marketing, new Map([...Array.from(DesktopDefaults.entries())])], [Flow.Sales, new Map([...Array.from(DesktopDefaults.entries())])], [Flow.Service, new Map([...Array.from(DesktopDefaults.entries())])], [Flow.Integrations, new Map([...Array.from(DesktopDefaults.entries())])], [Flow.DirectoryListing, new Map([...Array.from(DesktopDefaults.entries())])], [Flow.CmsFree, new Map([...Array.from(DesktopDefaults.entries())])], [Flow.Academy, new Map([...Array.from(DesktopDefaults.entries()), [Step.JobField, null]])], [Flow.HubSpotPartners, new Map([...Array.from(DesktopDefaults.entries()), [Step.OptionalPhoneNumber, null]])], [Flow.HubspotForStartups, new Map([...Array.from(DesktopDefaults.entries()), [Step.OptionalPhoneNumber, null]])], [Flow.Microapp, new Map([...Array.from(DesktopDefaults.entries())])]]);
const MobileMessaging = new Map([[Flow.Crm, new Map([...Array.from(MobileDefaults.entries())])], [Flow.Marketing, new Map([...Array.from(MobileDefaults.entries())])], [Flow.Sales, new Map([...Array.from(MobileDefaults.entries())])], [Flow.Service, new Map([...Array.from(MobileDefaults.entries())])], [Flow.Integrations, new Map([...Array.from(MobileDefaults.entries()), [Step.WordpressAuthorization, MessageKey.WordpressConnect], [Step.WordpressConnectRedirect, MessageKey.WordpressConnect]])], [Flow.Integrations, new Map([...Array.from(MobileDefaults.entries()), [Step.IntegratedAppAuthorization, MessageKey.ShopifyEmbeddedConnect], [Step.IntegratedAppConnectRedirect, MessageKey.ShopifyEmbeddedConnect]])], [Flow.CmsFree, new Map([...Array.from(MobileDefaults.entries())])], [Flow.Academy, new Map([...Array.from(DesktopDefaults.entries()), [Step.JobField, null]])], [Flow.HubSpotPartners, new Map([...Array.from(DesktopDefaults.entries()), [Step.OptionalPhoneNumber, null]])], [Flow.HubspotForStartups, new Map([...Array.from(DesktopDefaults.entries()), [Step.OptionalPhoneNumber, null]])], [Flow.Microapp, new Map([...Array.from(DesktopDefaults.entries())])]]);
export const MessagingConfig = {
  [Platform.Desktop]: DesktopMessaging,
  [Platform.Mobile]: MobileMessaging
};