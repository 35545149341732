import { Step } from 'signup-constants/Step';
import { ErrorKey } from '../app-active-error/ErrorKey';
import { NextStepManager } from '../flow-management/NextStepManager';
import { setNextStep } from '../flow-management/setNextStep';
import Logger from '../lib/Logger';
import { Metrics } from '../metrics/Metrics';
import { MetricsCounter } from '../metrics/MetricsCounter';
import { setError } from '../store/error/action-creators';
import { getRecaptchaSiteKey, getRecaptchaToken } from '../store/google-recaptcha/selectors';
import parseErrorData from '../store/signup-data-validation/utils/parseErrorData';
import { Tracker } from '../store/tracking/action-creators';
import { LoadingKey } from '../app-active-loading/LoadingKey';
import { clearLoadingStatus } from '../store/loading-status/action-creators';
import { codeVerificationExpiredRefreshInitiate } from '../store/step-code-verification/action-creators';
import { setVerificationType, updateVerificationExpired } from '../store/app/action-creators';
import { VerificationType } from '../app/VerificationType';
import { Data } from 'signup-constants/signupData/Data';
import { bulkEmptySignupDataAndSetFresh } from '../store/signup-data/action-creators';
import { getVerificationType } from '../store/app/selectors';
const LOG = Logger.getLogger('handleSignupError');
export const handleSignupError = (error, dispatch, getState) => {
  const statusCode = error.status;
  let message = 'unknown-message';
  let errorType = '';
  if (error.data) {
    const parsed = parseErrorData(error.data);
    message = parsed.message;
    errorType = parsed.errorType;
  }
  LOG.error('Signup Request Error', {
    extra: {
      message,
      statusCode
    }
  });
  if (statusCode === 429) {
    dispatch(Tracker.signupInteraction('creation-error-rate-limited'));
    return dispatch(setError(ErrorKey.RateLimitedCreationError, {
      message
    }));
  }
  if (error.data) {
    if (statusCode === 404 && errorType === 'EMAIL_VERIFICATION_NOT_FOUND') {
      if (message) {
        dispatch(Tracker.creationError('creation-error-standard', `${statusCode} - ${message}`));
      }
      dispatch(Tracker.signupInteraction('signup-error-verification-expired'));
      dispatch(updateVerificationExpired(true));
      dispatch(setVerificationType(VerificationType.EmailByCode));
      dispatch(bulkEmptySignupDataAndSetFresh([Data.CodeVerification]));
      dispatch(codeVerificationExpiredRefreshInitiate());
      dispatch(setNextStep({
        rebuilder: NextStepManager.pushNextSteps(Step.CodeVerification)
      }));
      return dispatch(clearLoadingStatus(LoadingKey.SignupRequest));
    }
    if (message && message.indexOf('verify_user_first') >= 0) {
      dispatch(Tracker.signupInteraction('creation-error-verify-first'));
      return dispatch(setError(ErrorKey.VerifyFirstCreationError, {
        message
      }));
    } else if (message === 'recaptcha failed') {
      LOG.error('/signup recaptcha error', {
        extra: {
          message,
          statusCode,
          recaptchaToken: getRecaptchaToken(getState()),
          recaptchaSiteKey: getRecaptchaSiteKey(getState())
        }
      });
      const verificationType = getVerificationType(getState()) || '';
      Metrics.counter(MetricsCounter.RecaptchaSignupError, {
        verificationType
      }).increment();
      return dispatch(setError(ErrorKey.RecaptchaCreationError, {
        message
      }));
    } else if (message === 'The recaptchaToken should be present on the request payload' || message === 'The recaptchaSiteKey should be present on the request payload' || message === 'The recaptcha token & site key should be present on the request payload') {
      return dispatch(setError(ErrorKey.RecaptchaMissingInformationError, {
        message
      }));
    } else if (message) {
      dispatch(Tracker.creationError('creation-error-standard', `${statusCode} - ${message}`));
    } else {
      dispatch(Tracker.creationError('creation-error-standard', 'error-unknown'));
    }
  }
  return dispatch(setError(ErrorKey.StandardCreationError, {
    message
  }));
};