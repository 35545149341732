import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { Data } from 'signup-constants/signupData/Data';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { getDomainFromEmail } from '../utils/getDomainFromEmail';
import { setEndpointTimeStarted } from '../store/http-responses/action-creators';
import { EndpointKey } from '../http/EndpointKey';
import { setSignupDataValue } from '../store/signup-data/action-creators';
import { isTestingOrInternalEmailDomain } from '../utils/isTestingOrInternalEmailDomain';
const fetchCompanyInfo = domain => {
  const COMPANY_INFO_URL = 'acquisition/v1/companyinfo/research';
  return noAuthApiClient.get(`${COMPANY_INFO_URL}?domain=${domain}`, {
    timeout: 8000
  });
};
export const getBreezeCompanyInfo = (state, dispatch) => {
  const email = getSignupDataValue(state, Data.Email);
  const domain = getDomainFromEmail(email);
  dispatch(setEndpointTimeStarted(EndpointKey.BreezeCompanyInfo, new Date()));
  if (isTestingOrInternalEmailDomain(domain)) {
    dispatch(setSignupDataValue(Data.CompanyDomain, 'hubspot-test.com'));
  } else {
    dispatch(setSignupDataValue(Data.CompanyDomain, domain));
  }
  return fetchCompanyInfo(domain);
};