import styled, { css, keyframes } from 'styled-components';
import { OLAF } from 'HubStyleTokens/colors';
// Constants for the animation duration and word count for the text animation
// Maximum words we have in biggest use case
// Total animation duration in seconds
const MAX_WORDS = 6;
const TOTAL_ANIMATION_DURATION = 4;

// Function to calculate the animation duration based on the word count of the text so that all use case have relatively similar animation duration
const calculateAnimationDuration = text => {
  const wordCount = text === null || text === void 0 ? void 0 : text.split(' ').length;
  const duration = wordCount && wordCount / MAX_WORDS * TOTAL_ANIMATION_DURATION;
  return duration && duration <= 2 ? 3 : duration;
};
export const TextAnimatedContainer = styled.div.withConfig({
  displayName: "TextAnimationsstyles__TextAnimatedContainer",
  componentId: "sc-1f4ysvg-0"
})(["transition:background 0.15s ease-in-out !important;display:inline-block;"]);
const textAnimationKeyframes = keyframes(["40%,60%{left:calc(100%);}100%{left:0;}"]);
const blink = keyframes(["to{background:transparent;}"]);
export const TextAnimatedSpan = styled.span.withConfig({
  displayName: "TextAnimationsstyles__TextAnimatedSpan",
  componentId: "sc-1f4ysvg-1"
})(["color:#ff5c35 !important;text-align:left;position:relative;display:block;width:100%;&::before,&::after{content:'';position:absolute;top:0;right:0;bottom:0;left:0;}&::before{background:", ";", "}&::after{width:0.125em;background:", ";", "}"], OLAF, props => css(["animation:", " ", "s 1.5s forwards linear;"], textAnimationKeyframes, calculateAnimationDuration(props.text)), OLAF, props => css(["animation:", " ", "s 1.5s forwards linear,", " 750ms infinite;"], textAnimationKeyframes, calculateAnimationDuration(props.text), blink));