import { Flow } from 'signup-constants/Flow';
import { ExperimentKeys, TEST_KEY } from './ExperimentKeys';
import { enrollOnFlow } from './experimentDefaults';
import { getIsNewUser } from '../store/app/selectors';
import { getFlowKey } from '../routing/getFlowKey';
import { getIsMobile } from '../utils/deviceUtils';
import { isBrowserAndApplicationLanguageForLocale } from '../i18n/LanguageUtils';
import { getPreferredLocale } from '../i18n/LocaleUtils';
import { Locale } from 'signup-ui-lego-core/constants/Locale';
import { getIsNewUserExperiment } from './ExperimentUtils';
import { getQueryParam } from '../routing/getQueryParam';
export const EXPERIMENT_ID = 'experimentId';
const SEEN_EXPERIMENTS = new Set();
const Experiments = new Map([[ExperimentKeys.ACQ0164, {
  shouldEnroll: state => {
    const isMobile = getIsMobile();
    const isNewUser = getIsNewUser(state);
    const isCrmFlow = getFlowKey() === Flow.Crm;
    const isChineseLanguage = isBrowserAndApplicationLanguageForLocale(Locale.zhTw) || getPreferredLocale() === Locale.zhTw;
    return isNewUser && isCrmFlow && !isMobile && !isChineseLanguage;
  }
}], [ExperimentKeys.ACQ0165, {
  shouldEnroll: state => {
    const isNewUser = getIsNewUser(state);
    const activeFlows = [Flow.Crm, Flow.Sales, Flow.Marketing, Flow.Service];
    const isOnActiveFlow = activeFlows.includes(getFlowKey());
    return isNewUser && isOnActiveFlow;
  }
}], [ExperimentKeys.ACQ0167, {
  shouldEnroll: state => {
    const isNewUser = getIsNewUserExperiment(state) || getIsNewUser(state);
    const activeFlows = [Flow.Crm, Flow.Sales, Flow.Marketing, Flow.Service];
    const isOnActiveFlow = activeFlows.includes(getFlowKey());
    const researchAgentEnabledQueryParam = getQueryParam('research_agent_enabled') === 'true';
    return isNewUser && isOnActiveFlow && researchAgentEnabledQueryParam;
  }
}]]);
export const getExperiment = id => {
  // we want to prefer this solution over mocking getExperiment so that the
  // seen experiments tracking below continues to work and doesn't get
  // accidentally mocked
  if (id === TEST_KEY) {
    return {
      shouldEnroll: () => enrollOnFlow([Flow.Crm])
    };
  }
  SEEN_EXPERIMENTS.add(id);
  return Experiments.get(id);
};
export function getSeenExperimentsForTesting() {
  return Array.from(SEEN_EXPERIMENTS);
}