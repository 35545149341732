import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Data } from 'signup-constants/signupData/Data';
import { getMessage } from '../messaging/MESSAGE';
import { getMessageKey } from '../messaging/getMessageKey';
import { prefetchImage as defaultPrefetchImage } from '../preload/prefetchImage';
import { prefetchSourceSet as defaultPrefetchSourceSet } from '../preload/prefetchSourceSet';
import { useResponsiveness } from '../responsiveness/ResponsivenessProvider';
import { useSignupData } from '../signup-data/useSignupData';
import { getNextStep } from '../store/flow/selectors';
import { cancelIdleCallback, requestIdleCallback } from '../utils/idleCallback';
const loadImagesForMessageKey = (messageKey, preloadImage, preloadSourceSet) => {
  const messageObject = getMessage(messageKey);
  const sourcesToPreload = [];
  const sourcesSetsToPreload = [];
  if (!messageObject) {
    return undefined;
  }
  if ('imageUrl' in messageObject) {
    const flattenedArray = [messageObject.imageUrl].flat();
    sourcesSetsToPreload.push([...flattenedArray]);
  }
  if (sourcesToPreload.length + sourcesSetsToPreload.length > 0) {
    return requestIdleCallback(() => {
      sourcesToPreload.forEach(src => preloadImage(src));
      sourcesSetsToPreload.forEach(srcSet => preloadSourceSet(srcSet));
    });
  }
  return undefined;
};
export const usePreloadNextStepMessage = ({
  preloadImage = defaultPrefetchImage,
  preloadSourceSet = defaultPrefetchSourceSet
} = {}) => {
  const {
    data: industry
  } = useSignupData(Data.Industry);
  const {
    isMobile
  } = useResponsiveness();
  const nextStep = useSelector(getNextStep);
  const messageKey = nextStep ? getMessageKey({
    currentStep: nextStep,
    industry,
    isMobile
  }) : null;
  useEffect(() => {
    let callbackId;
    if (messageKey) {
      callbackId = loadImagesForMessageKey(messageKey, preloadImage, preloadSourceSet);
    }
    return () => {
      if (callbackId) {
        cancelIdleCallback(callbackId);
      }
    };
  }, [messageKey, preloadImage, preloadSourceSet]);
};