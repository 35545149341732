import styled from 'styled-components';
import { devices } from 'signup-constants/styles/viewports.styles';
import { CALYPSO_MEDIUM } from 'HubStyleTokens/colors';
import { AnimatedButton } from 'signup-components/styles/ButtonAnimations.styles';
import { getStepConfig } from '../views/getStepConfig';
import { isLeftAlignedLayout } from '../utils/isLeftAlignedLayout';
const getButtonColumnCountEmbedded = currentStep => {
  const stepConfig = getStepConfig(currentStep);
  const stepButtonColumnConfig = stepConfig && stepConfig.stepToButtonColumn;
  return stepButtonColumnConfig && stepButtonColumnConfig.embedded ? stepButtonColumnConfig.embedded : 1;
};
const getButtonColumnCountDesktop = (currentStep, isEmbedded, isFramedCentredStepLayout) => {
  const stepConfig = getStepConfig(currentStep);
  const stepButtonColumnConfig = stepConfig && stepConfig.stepToButtonColumn;
  if (isEmbedded || isFramedCentredStepLayout) {
    return getButtonColumnCountEmbedded(currentStep);
  }
  return stepButtonColumnConfig ? stepButtonColumnConfig.default : 2;
};
const getButtonColumnCountMobile = (currentStep, isEmbedded) => {
  const stepConfig = getStepConfig(currentStep);
  const stepButtonColumnConfig = stepConfig && stepConfig.stepToButtonColumn;
  if (isEmbedded) {
    return getButtonColumnCountEmbedded(currentStep);
  }
  return stepButtonColumnConfig && stepButtonColumnConfig.mobile ? stepButtonColumnConfig.mobile : 1;
};
export const getButtonColumnCount = (currentStep, isMobile, isEmbedded, isFramedCentredStepLayout) => {
  if (isMobile) {
    return getButtonColumnCountMobile(currentStep, isEmbedded);
  }
  if (isEmbedded || isFramedCentredStepLayout) {
    return getButtonColumnCountEmbedded(currentStep);
  }
  return getButtonColumnCountDesktop(currentStep, isEmbedded, isFramedCentredStepLayout);
};
export const ButtonsGridStyled = styled.div.withConfig({
  displayName: "ButtonsGridstyles__ButtonsGridStyled",
  componentId: "sc-7xd3u5-0"
})(["--column-count:", ";@media ", "{--column-count:", ";", "}--total_columns:calc(var(--column-count) * 2);--span:calc(var(--total_columns) / var(--column-count));display:grid;grid-template-columns:repeat(var(--total_columns),minmax(0,1fr));grid-auto-rows:auto;grid-gap:20px;& > *{grid-column:span var(--span);", "}", ""], props => props.$columnCount || getButtonColumnCountMobile(props.$currentStep, props.theme.embedded), devices.desktop, props => props.$columnCount || getButtonColumnCountDesktop(props.$currentStep, props.theme.embedded, props.$isFramedCentredStepLayout), props => isLeftAlignedLayout(props.$layoutType) && (getButtonColumnCountDesktop(props.$currentStep, props.theme.embedded, props.$isFramedCentredStepLayout) === 1 || props.$columnCount === 1) && `width: 420px;`, props => props.$shouldCenter && `	&:nth-last-child(${props.$offset}):nth-child(${props.$nthChild}) {
			grid-column: var(--span) / span var(--span);
		}`, props => props.$addTopPadding && `padding-top: 20px;`);
export const ButtonsGridButtonHolder = styled.div.withConfig({
  displayName: "ButtonsGridstyles__ButtonsGridButtonHolder",
  componentId: "sc-7xd3u5-1"
})(["min-height:", ";min-width:0;display:grid;"], ({
  minHeight
}) => minHeight || '5rem');
export const ButtonsGridButton = styled(AnimatedButton).withConfig({
  displayName: "ButtonsGridstyles__ButtonsGridButton",
  componentId: "sc-7xd3u5-2"
})(["height:100%;p{font-size:17px;font-weight:400;}@media ", "{p{font-size:17px;}&:focus{outline:none;box-shadow:0 0 0 2px ", ";}}@media ", "{p{font-size:17px;}&:focus{outline:none;box-shadow:0 0 0 2px ", ";}}"], devices.desktop, CALYPSO_MEDIUM, devices.tablet, CALYPSO_MEDIUM);
export const StyledIllustrationContainer = styled.div.withConfig({
  displayName: "ButtonsGridstyles__StyledIllustrationContainer",
  componentId: "sc-7xd3u5-3"
})(["text-align:center;margin-top:20px;padding-top:10px;min-width:90px;min-height:90px;"]);
export const StyledButtonText = styled.p.withConfig({
  displayName: "ButtonsGridstyles__StyledButtonText",
  componentId: "sc-7xd3u5-4"
})(["font-weight:500 !important;margin:0 !important;", ""], props => props.isHubletButtonText && `padding-top: 5px; text-align: left;`);