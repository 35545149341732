import { isValidEndpointKey } from '../../http/EndpointKey';
import { HttpResponseActionTypes } from './action-types';
import { HttpStatus } from './types';
import { getRetriesInitialState } from './getRetriesInitialState';
export const initialHttpResponsesState = {
  statuses: {},
  responses: {},
  retries: getRetriesInitialState(),
  timings: {}
};
const httpResponsesReducer = (state = initialHttpResponsesState, action) => {
  if (!isValidEndpointKey(action.endpointKey)) {
    return state;
  }
  switch (action.type) {
    case HttpResponseActionTypes.RequestFetch:
      return Object.assign({}, state, {
        statuses: Object.assign({}, state.statuses, {
          [action.endpointKey]: HttpStatus.Fetching
        })
      });
    case HttpResponseActionTypes.Success:
      return Object.assign({}, state, {
        responses: Object.assign({}, state.responses, {
          [action.endpointKey]: action.response
        }),
        statuses: Object.assign({}, state.statuses, {
          [action.endpointKey]: HttpStatus.Success
        })
      });
    case HttpResponseActionTypes.Error:
      return Object.assign({}, state, {
        responses: Object.assign({}, state.responses, {
          [action.endpointKey]: action.error
        }),
        statuses: Object.assign({}, state.statuses, {
          [action.endpointKey]: HttpStatus.Error
        })
      });
    case HttpResponseActionTypes.RetryEndpoint:
      return Object.assign({}, state, {
        retries: Object.assign({}, state.retries, {
          [action.endpointKey]: state.retries[action.endpointKey] + 1
        })
      });
    case HttpResponseActionTypes.SetTimeStarted:
      return Object.assign({}, state, {
        timings: Object.assign({}, state.timings, {
          [action.endpointKey]: Object.assign({}, state.timings[action.endpointKey], {
            timeStarted: action.time
          })
        })
      });
    case HttpResponseActionTypes.SetTimeCompleted:
      return Object.assign({}, state, {
        timings: Object.assign({}, state.timings, {
          [action.endpointKey]: Object.assign({}, state.timings[action.endpointKey], {
            timeCompleted: action.time
          })
        })
      });
    default:
      return state;
  }
};
export default httpResponsesReducer;