import styled from 'styled-components';
import { PANTERA, EERIE } from 'HubStyleTokens/colors';
export const StyledAccountCard = styled.div.withConfig({
  displayName: "AccountCardstyles__StyledAccountCard",
  componentId: "el0mig-0"
})(["margin:16px 0;max-width:18.75rem;border-radius:10px;box-shadow:rgba(0,0,0,0.25) 0 25px 50px -12px;overflow:hidden;width:100%;", " ", ";"], props => props.theme.embedded && `box-shadow:none;`, props => props.theme.embedded && props.shouldShowBoxAccountCard && `box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.25) 0 25px 50px -12px;`);
export const AccountCardUserName = styled.div.withConfig({
  displayName: "AccountCardstyles__AccountCardUserName",
  componentId: "el0mig-1"
})(["margin:1rem auto 0.2rem;font-size:1.4rem;font-weight:600;color:", ";"], PANTERA);
export const AccountCardEmail = styled.div.withConfig({
  displayName: "AccountCardstyles__AccountCardEmail",
  componentId: "el0mig-2"
})(["color:", ";white-space:nowrap;text-overflow:ellipsis;overflow-x:hidden;overflow-y:hidden;"], EERIE);