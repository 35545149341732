import styled from 'styled-components';
export const StyledGoogleOAuthButton = styled.div.withConfig({
  displayName: "GoogleOAuthButtonstyles__StyledGoogleOAuthButton",
  componentId: "choey-0"
})(["display:block;max-height:42px;"]);
export const StyledGoogleOAuthContainer = styled.div.withConfig({
  displayName: "GoogleOAuthButtonstyles__StyledGoogleOAuthContainer",
  componentId: "choey-1"
})(["height:36px;max-width:420px;display:flex;align-items:center;justify-content:center;margin-right:auto;margin-left:auto;padding:2px 1rem;width:100%;margin-top:24px;"]);
export const StyledGoogleLogoPlaceholder = styled.div.withConfig({
  displayName: "GoogleOAuthButtonstyles__StyledGoogleLogoPlaceholder",
  componentId: "choey-2"
})(["display:block;width:36px;height:36px;"]);
export const StyledGoogleOAuthDiv = styled.div.withConfig({
  displayName: "GoogleOAuthButtonstyles__StyledGoogleOAuthDiv",
  componentId: "choey-3"
})(["width:100%;min-height:40px;display:flex;border-radius:4px;overflow:hidden;background:#1a73e8;"]);
export const StyledGoogleLogoContainer = styled.div.withConfig({
  displayName: "GoogleOAuthButtonstyles__StyledGoogleLogoContainer",
  componentId: "choey-4"
})(["display:flex;height:36px;width:36px;justify-content:center;background:white;align-items:center;"]);
export const StyledGoogleLoadingContainer = styled.div.withConfig({
  displayName: "GoogleOAuthButtonstyles__StyledGoogleLoadingContainer",
  componentId: "choey-5"
})(["margin:auto;"]);
export const StyledGoogleOAuthFallbackButtonContainer = styled.div.withConfig({
  displayName: "GoogleOAuthButtonstyles__StyledGoogleOAuthFallbackButtonContainer",
  componentId: "choey-6"
})(["max-width:420px;padding:0 1rem;width:100%;margin:0 auto;height:36px;"]);
export const StyledGoogleOAuthFallbackButtonContent = styled.div.withConfig({
  displayName: "GoogleOAuthButtonstyles__StyledGoogleOAuthFallbackButtonContent",
  componentId: "choey-7"
})(["width:100%;font-size:14px;display:flex;align-items:center;justify-content:center;height:40px;padding:1px 2px;"]);
export const StyledGoogleOAuthFallbackButtonText = styled.span.withConfig({
  displayName: "GoogleOAuthButtonstyles__StyledGoogleOAuthFallbackButtonText",
  componentId: "choey-8"
})(["width:100%;text-align:center;"]);