import { Flow } from 'signup-constants/Flow';
import { SignupEmbedder } from 'signup-embedder';
import { isFlow } from '../utils/isFlow';
import { Step } from 'signup-constants/Step';
export const getExistingUserStep = () => {
  const isEmbedded = SignupEmbedder.isEmbedded();

  // check if is Academy Flow and embedded flow
  if (isFlow(Flow.Academy) && isEmbedded) {
    return Step.AcademyEmbeddedExistingUser;
  }
  return Step.ExistingUser;
};