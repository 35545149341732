import { Data } from 'signup-constants/signupData/Data';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { Step } from 'signup-constants/Step';
import { getCurrentStep } from '../store/flow/selectors';
export const getShouldPreFillCompanyName = (state, name) => {
  const userCompanyName = getSignupDataValue(state, Data.CompanyName);
  const shouldPreFillCompanyNameByFlowConfig = getFlowConfig().shouldPreFillCompanyName;
  const currentStep = getCurrentStep(state);
  return !userCompanyName && Boolean(name) && shouldPreFillCompanyNameByFlowConfig && currentStep !== Step.CompanyName;
};