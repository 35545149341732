import { useExperiment } from '../experiments/useExperiment';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { TrackWhen } from '../experiments/useExperiment';
import { ExperimentParameterGroup } from '../experiments/ExperimentParameterGroup';
import { useCurrentStep } from './useCurrentStep';
import { useSignupData } from '../signup-data/useSignupData';
import { Data } from 'signup-constants/signupData/Data';
export const useACQ167Experiment = () => {
  const {
    currentStep
  } = useCurrentStep();
  const {
    isPrefilled
  } = useSignupData(Data.CompanySize);
  const {
    isAssignedToSpecificVariant
  } = useExperiment(ExperimentKeys.ACQ0167, TrackWhen.never);
  const isAssignedToACQ167Variant_B = isAssignedToSpecificVariant(ExperimentParameterGroup.Variant_B);
  const isPrefilledAndAssignedToACQ167Variant_B = isPrefilled && isAssignedToACQ167Variant_B;
  const acq167HelpText = isAssignedToACQ167Variant_B ? `views.${currentStep}.acq0167HelperText` : `views.${currentStep}.help`;
  return {
    isAssignedToACQ167Variant_B,
    acq167HelpText,
    isPrefilledAndAssignedToACQ167Variant_B
  };
};