import { EndpointKey } from '../../http/EndpointKey';
import { Data } from 'signup-constants/signupData/Data';
import { HttpResponseActionTypes } from '../http-responses/action-types';
import { TypeaheadActionTypes } from './action-types';
const initialInnerState = {
  predictionsFetched: false,
  predicted: [],
  trackedUserInput: '',
  clickedToSeeAll: false,
  selectedValue: '',
  inputValue: ''
};
export const initialTypeaheadState = {
  [Data.Industry]: initialInnerState,
  [Data.JobRole]: initialInnerState
};
const industryReducer = (state = initialTypeaheadState, action) => {
  switch (action.type) {
    case HttpResponseActionTypes.Error:
      if (action.endpointKey === EndpointKey.CompanyInfo || action.endpointKey === EndpointKey.BreezeCompanyInfo) {
        return Object.assign({}, state, {
          [Data.Industry]: Object.assign({}, state[Data.Industry], {
            predictionsFetched: true
          })
        });
      }
      return state;
    case TypeaheadActionTypes.UpdateTrackedUserInput:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          trackedUserInput: action.trackedUserInput
        })
      });
    case TypeaheadActionTypes.UpdateSelectedValue:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          selectedValue: action.selectedValue
        })
      });
    case TypeaheadActionTypes.UpdateInputValue:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          inputValue: action.inputValue
        })
      });
    case TypeaheadActionTypes.StorePredicted:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          predicted: action.predicted,
          predictionsFetched: true
        })
      });
    case TypeaheadActionTypes.ClearPredicted:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          predictedIndustries: [],
          predictionsFetched: false
        })
      });
    case TypeaheadActionTypes.SetClickedToSeeAll:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          clickedToSeeAll: true
        })
      });
    default:
      return state;
  }
};
export default industryReducer;