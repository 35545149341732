import { Data } from 'signup-constants/signupData/Data';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { bulkUpdate } from '../store/signup-data/action-creators';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { Tracker } from '../store/tracking/action-creators';
import { storePredictedTypeaheadValues } from '../store/typeahead/action-creators';
import { arrayContainsAll } from '../utils/data-structure-utils/arrayContainsAll';
import { getDomainFromEmail } from '../utils/getDomainFromEmail';
import { SignupDataActionTypes } from '../store/signup-data/action-types';
import { setIsFreeEmailDomain } from '../store/app/action-creators';
import { PrefillType } from '../prefilling/prefillSignupData';
import { handleCompanySizeUpdate } from '../company-info/companySize';
import { getShouldPreFillCompanyName } from '../company-info/companyName';
import { getShouldUpdateDomain } from '../company-info/domain';
import { getPredictedIndustries } from '../company-info/industries';
const LOG = Logger.getLogger('handleCompanyInfo');
export const handleCompanyInfoSuccess = ({
  domain,
  name,
  industries = [],
  isFreeEmailDomain,
  employeesRange
}, dispatch, getState) => {
  const state = getState();
  dispatch(Tracker.signupInteraction('company-info-prefill-success'));
  const email = getSignupDataValue(state, Data.Email);
  handleCompanySizeUpdate(employeesRange, dispatch);
  const shouldPreFillCompanyName = getShouldPreFillCompanyName(state, name);
  if (shouldPreFillCompanyName) {
    dispatch(Tracker.signupInteraction('company-name-prefill-success'));
  }
  const {
    shouldUpdateDomain,
    isTestDomain
  } = getShouldUpdateDomain(state, domain, email);
  if (isTestDomain) {
    domain = 'hubspot-test.com';
  }
  if (shouldUpdateDomain) {
    dispatch(Tracker.signupInteraction('company-domain-prefill-success'));
  }
  const {
    predicted,
    industryNames
  } = getPredictedIndustries(industries);
  dispatch(Tracker.signupInteraction(predicted ? 'industry-prefill-success' : 'industry-prefill-empty-industries', {
    numberOfDisplayed: industryNames.length
  }));
  const dataToUpdate = Object.assign({}, shouldUpdateDomain && domain && {
    [Data.CompanyDomain]: domain
  }, shouldPreFillCompanyName && name && {
    [Data.CompanyName]: name
  });
  const prefilledData = [...Object.keys(dataToUpdate), ...[industryNames.length > 0 ? [Data.Industry] : []]];
  if (isFreeEmailDomain) {
    dispatch(setIsFreeEmailDomain());
  }
  dispatch(bulkUpdate(dataToUpdate));
  dispatch(storePredictedTypeaheadValues(industryNames, Data.Industry));
  dispatch({
    type: SignupDataActionTypes.StampPrefilled,
    prefilledKeys: prefilledData,
    prefillType: PrefillType.CompanyInfo
  });
};
export const handleCompanyInfoError = (error, dispatch, getState) => {
  dispatch(Tracker.signupInteraction(`industry-prefill-${error.status}`));
  dispatch(Tracker.signupInteraction(`company-info-prefill-error-${error.status}`));
  const email = getSignupDataValue(getState(), Data.Email);
  const domainFromEmail = getDomainFromEmail(email);

  // Here we only log unknown statuses.
  // 404 - Returned when we don't find any company information for the requested domain
  // 503 - Returned when the Biden API is down or unreachable.
  if (!arrayContainsAll({
    array: [404, 503],
    element: error.status
  })) {
    LOG.error('Company Information Request Failed', {
      extra: Object.assign({}, getSentryExtra(error), {
        domainFromEmail
      })
    });
  }
};