import { ViaParam } from 'growth-onboarding-signup-redirect/types';
import { Integration } from 'signup-constants/Integration';
import { AppName } from 'signup-ui-lego-core/constants/AppName';
import { isIntegrationValid } from 'signup-ui-lego-core/utils/isIntegrationValid';
import { ConvertGoalType } from '../convert/ConvertGoalType';
import { DefaultFlowOptions } from '../flow-initial/FlowOptions';
import { SignupApiFlowPath } from '../http-clients/SignupApiFlowPath';
import { getIntegrationKey } from '../routing/getIntegrationKey';
import { getHasIntegratedAppAuthSucceeded } from '../store/integrations/selectors';
import { isGoogleSignupSupported } from '../google-authentication/utils/isGoogleSignupSupported';
import { OAuthProviders } from '../oauth/OAuthProviders';
export const defaultIntegrationsFlowOptions = Object.assign({}, DefaultFlowOptions, {
  appName: AppName.Integrations,
  viaParam: ViaParam.IntegrationsSignup,
  oAuthsEnabled: {
    [OAuthProviders.Google]: isGoogleSignupSupported(),
    [OAuthProviders.Microsoft]: true,
    [OAuthProviders.Apple]: true
  }
});
const FlowOptionsByIntegration = {
  [Integration.Shopify]: defaultIntegrationsFlowOptions,
  [Integration.Typeform]: defaultIntegrationsFlowOptions,
  [Integration.Wpforms]: defaultIntegrationsFlowOptions,
  [Integration.GoogleWorkspaceApp]: Object.assign({}, defaultIntegrationsFlowOptions, {
    shouldShowBoxAccountCard: true,
    shouldShowTitleOnExistingUserView: true
  }),
  [Integration.ShopifyEmbedded]: Object.assign({}, defaultIntegrationsFlowOptions, {
    shouldShowBoxAccountCard: true,
    shouldShowTitleOnExistingUserView: true,
    shouldShowSignInButton: false,
    canRedirect: state => getHasIntegratedAppAuthSucceeded(state)
  }),
  [Integration.Wordpress]: Object.assign({}, defaultIntegrationsFlowOptions, {
    shouldShowBoxAccountCard: true,
    shouldShowTitleOnExistingUserView: true,
    shouldShowSignInButton: false,
    signupApiFlowPath: SignupApiFlowPath.Wordpress,
    appName: AppName.Wordpress,
    convertGoal: {
      [ConvertGoalType.SignupInitiated]: '100314458',
      [ConvertGoalType.SignupCompleted]: '10038690',
      [ConvertGoalType.ExistingUser]: '100314459'
    },
    canRedirect: state => getHasIntegratedAppAuthSucceeded(state)
  })
};
export const getIntegrationFlowOptions = () => {
  const integrationKey = getIntegrationKey();
  if (!integrationKey || !isIntegrationValid(integrationKey)) {
    return defaultIntegrationsFlowOptions;
  }
  return FlowOptionsByIntegration[integrationKey];
};