import enviro from 'enviro';
import { getFlowKey } from '../../routing/getFlowKey';
import { AppKey } from '../../app/AppKey';
import { isPostVerification, isPreVerification } from '../../flow-settings/settingsSelectors';
import { authIsFinishedPreVerification } from '../../store/auth/selectors';
import { arePreAccountCreatedExperimentsFetched } from '../experiments/selectors';
import { isEndpointStatusSuccess } from '../http-responses/selectors';
import { EndpointKey } from '../../http/EndpointKey';
import { isOAuthSignup } from '../../app/verificationTypeSelectors';
export const getAppState = state => state.appState;
export const getAppKeyValue = key => state => {
  return getAppState(state)[key];
};
export const getIsNewPortalCreated = getAppKeyValue(AppKey.IsNewPortalCreated);
export const getIsNewUser = getAppKeyValue(AppKey.IsNewUser);
export const getVerificationType = getAppKeyValue(AppKey.VerificationType);
export const getHasRefreshedNewPortalAuth = getAppKeyValue(AppKey.HasRefreshedNewPortalAuth);
export const getHublet = state => getAppKeyValue(AppKey.Hublet)(state) || enviro.getHublet();
export const getCountryCode = getAppKeyValue(AppKey.Country);
export const getIsCompleteVerificationComplete = state => {
  return isPostVerification() ? getAppKeyValue(AppKey.CompleteVerificationComplete)(state) : true;
};
export const isPostVerificationInitialized = state => {
  const experimentsFetched = arePreAccountCreatedExperimentsFetched(state);
  const completeVerificationIsNotComplete = isPostVerification() && !getIsCompleteVerificationComplete(state);
  return getFlowKey() && experimentsFetched && !completeVerificationIsNotComplete;
};
export const getIsOAuthExistingUser = getAppKeyValue(AppKey.IsOAuthExistingUser);
export const isPreVerificationInitialized = state => {
  const authIsFinished = authIsFinishedPreVerification(state);
  const experimentsFetched = arePreAccountCreatedExperimentsFetched(state);
  const isNotOAuthExistingUser = !getIsOAuthExistingUser(state);
  const isInitialized = getFlowKey() && experimentsFetched && authIsFinished && isNotOAuthExistingUser;
  return isInitialized;
};
export const getIsFlowInitialized = state => {
  return isPreVerification() ? isPreVerificationInitialized(state) : isPostVerificationInitialized(state);
};
export const getIsUsingOptionalDomain = getAppKeyValue(AppKey.IsUsingOptionalDomain);
export const getIsExistingUserSession = getAppKeyValue(AppKey.IsExistingUserSession);
export const getRedirectUrl = getAppKeyValue(AppKey.RedirectUrl);
export const getIsVerifiedByGoogleCredential = getAppKeyValue(AppKey.IsVerifiedByGoogleCredential);
export const getWhichOAuthEndpointFailed = getAppKeyValue(AppKey.WhichOAuthEndpointFailed);
export const getWhichOAuthLoading = getAppKeyValue(AppKey.WhichOAuthLoading);
export const getIsNewUserCreated = getAppKeyValue(AppKey.IsNewUserCreated);
export const getWhichOAuthSelected = getAppKeyValue(AppKey.WhichOAuthSelected);
export const getIsVerificationExpired = getAppKeyValue(AppKey.VerificationExpired);
export const getRecommendedHublet = getAppKeyValue(AppKey.RecommendedHublet);

// Verification can be 'completed' even with error response.
// This selector checks if the verification was completed successfully.
export const getIsSuccessfullyVerified = state => {
  if (isOAuthSignup(state)) {
    return true;
  }
  const hasCompletedVerification = getIsCompleteVerificationComplete(state);
  const verificationRequestSucceeded = isEndpointStatusSuccess(EndpointKey.CompleteVerification)(state) || isEndpointStatusSuccess(EndpointKey.CompleteVerificationByCode)(state);
  return hasCompletedVerification && verificationRequestSucceeded;
};