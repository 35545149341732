import { Data } from 'signup-constants/signupData/Data';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { Step } from 'signup-constants/Step';
import { getCurrentStep } from '../store/flow/selectors';
import { isTestingOrInternalEmailDomain } from '../utils/isTestingOrInternalEmailDomain';
import { getDomainFromEmail } from '../utils/getDomainFromEmail';
import { getQueryParam } from '../routing/getQueryParam';
import { OPTIONAL_DOMAIN_QUERY_PARAM } from '../step-company-domain/useCompanyDomain';
export const getShouldUpdateDomain = (state, domain, email) => {
  const userDomain = getSignupDataValue(state, Data.CompanyDomain);
  const currentStep = getCurrentStep(state);
  const domainFromEmail = getDomainFromEmail(email);
  const hasOptionalDomainParam = getQueryParam(OPTIONAL_DOMAIN_QUERY_PARAM);
  const shouldUpdateDomain = !userDomain && Boolean(domain) && currentStep !== Step.CompanyDomain;
  if (isTestingOrInternalEmailDomain(domainFromEmail)) {
    return {
      shouldUpdateDomain: shouldUpdateDomain && !hasOptionalDomainParam,
      isTestDomain: true
    };
  }
  return {
    shouldUpdateDomain,
    isTestDomain: false
  };
};