import { useEffect, useState } from 'react';
export const useButtonGridFocus = (gridRef, optionsLength, shouldDisableAutofocus = false) => {
  const [focused, setFocused] = useState(false);
  useEffect(() => {
    let animationCount = 0;
    const gridElement = gridRef.current;
    const onAnimationEnd = () => {
      if (gridElement) {
        animationCount++;

        // Wait for the last button animation to focus the first button
        if (animationCount === optionsLength) {
          const button = gridElement.querySelector('#ButtonsGrid_Button');
          if (button && !shouldDisableAutofocus) {
            button.focus();
            setFocused(true);
          }
        }
      }
    };
    if (gridElement && !focused) {
      gridElement.addEventListener('animationend', onAnimationEnd);
    }
    return () => {
      if (gridElement) {
        gridElement.removeEventListener('animationend', onAnimationEnd);
      }
    };
  }, [gridRef, focused, setFocused, optionsLength, shouldDisableAutofocus]);
};