import LocaleUtilsModule from 'I18n/utils/getLang';
import getLangLocale from 'I18n/utils/getLangLocale';
import { Locale } from 'signup-ui-lego-core/constants/Locale';
import { LocaleName } from 'signup-ui-lego-core/constants/LocaleName';
import { LocaleNameByLocale } from 'signup-ui-lego-core/LocaleNameByLocale';
import { getIsQa, isQA } from '../constants/apiEnvironments';
import { getLanguages, getNavigatorLanguages } from './LanguageUtils';
import { getPreferredReferrer } from './ReferrerUtils';
const checkLangCode = value => {
  return Object.values(Locale).includes(value);
};
export const getPreferredLocale = (languages = getNavigatorLanguages(), referrer = getPreferredReferrer(), fallbackLocale = Locale.en) => {
  const url = new URL(document.location.href);
  let locale;
  if (url.searchParams.has('lang')) {
    const queryLang = url.searchParams.get('lang');
    const isLangLocaleSupported = checkLangCode(queryLang);
    if (isLangLocaleSupported) {
      locale = queryLang;
    } else {
      const lang = queryLang.split('-')[0];
      const isLangSupported = checkLangCode(lang);
      if (isLangSupported) {
        locale = lang;
      }
    }
    if (isQA) {
      locale = queryLang;
    }
  } else {
    languages = getLanguages(languages, referrer);
    if (languages && languages.length > 0) {
      const browserLang = languages[0].toLowerCase();
      const isLangLocaleSupported = checkLangCode(browserLang);
      if (isLangLocaleSupported) {
        locale = browserLang;
      } else {
        const lang = browserLang.split('-')[0];
        const isLangSupported = checkLangCode(lang);
        if (isLangSupported) {
          locale = lang;
        }
      }
    }
  }
  return locale || fallbackLocale;
};
export const getApplicationLocale = () => {
  const i18nLocale = getLangLocale();
  const i18nLang = LocaleUtilsModule();
  const localeValues = Object.values(Locale);
  if (getIsQa()) {
    const url = new URL(document.location.href);
    const queryLang = url.searchParams.get('lang');
    if (queryLang) {
      return queryLang;
    }
  }
  if (localeValues.includes(i18nLocale)) {
    return i18nLocale;
  }
  if (localeValues.includes(i18nLang)) {
    return i18nLang;
  }
  return Locale.en;
};
export const getLocaleName = (locale = getApplicationLocale()) => {
  return LocaleNameByLocale.has(locale) ? LocaleNameByLocale.get(locale) : LocaleName.en;
};