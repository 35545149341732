import { getShouldPreFillCompanyName } from '../company-info/companyName';
import { handleCompanySizeUpdate } from '../company-info/companySize';
import { Tracker } from '../store/tracking/action-creators';
import { getShouldUpdateDomain } from '../company-info/domain';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { Data } from 'signup-constants/signupData/Data';
import { getPredictedIndustries } from '../company-info/industries';
import { setIsFreeEmailDomain } from '../store/app/action-creators';
import { bulkUpdate } from '../store/signup-data/action-creators';
import { storePredictedTypeaheadValues } from '../store/typeahead/action-creators';
import { SignupDataActionTypes } from '../store/signup-data/action-types';
import { PrefillType } from '../prefilling/prefillSignupData';
import { getDomainFromEmail } from '../utils/getDomainFromEmail';
import { LOG } from '../http-complete-verification/handleCompleteVerificationError';
import { getSentryExtra } from '../error/getSentryExtra';
export const handleBreezeCompanyInfoSuccess = ({
  domain,
  name,
  industries = [],
  isFreeEmailDomain,
  employeesRange
}, dispatch, getState) => {
  const state = getState();
  const email = getSignupDataValue(state, Data.Email);
  dispatch(Tracker.signupInteraction('breeze-company-info-prefill-success'));
  handleCompanySizeUpdate(employeesRange, dispatch);
  const shouldPreFillCompanyName = getShouldPreFillCompanyName(state, name);
  if (shouldPreFillCompanyName) {
    dispatch(Tracker.signupInteraction('breeze-company-name-prefill-success'));
  }
  const {
    shouldUpdateDomain,
    isTestDomain
  } = getShouldUpdateDomain(state, domain, email);
  if (isTestDomain) {
    domain = 'hubspot-test.com';
  }
  if (shouldUpdateDomain) {
    dispatch(Tracker.signupInteraction('breeze-company-domain-prefill-success'));
  }
  const {
    predicted,
    industryNames
  } = getPredictedIndustries(industries);
  dispatch(Tracker.signupInteraction(predicted ? 'breeze-industry-prefill-success' : 'breeze-industry-prefill-empty-industries', {
    numberOfDisplayed: industryNames.length
  }));
  const dataToUpdate = Object.assign({}, shouldUpdateDomain && domain && {
    [Data.CompanyDomain]: domain
  }, shouldPreFillCompanyName && name && {
    [Data.CompanyName]: name
  });
  const prefilledData = [...Object.keys(dataToUpdate), ...[industryNames.length > 0 ? [Data.Industry] : []]];
  if (isFreeEmailDomain) {
    dispatch(setIsFreeEmailDomain());
  }
  dispatch(bulkUpdate(dataToUpdate));
  dispatch(storePredictedTypeaheadValues(industryNames, Data.Industry));
  dispatch({
    type: SignupDataActionTypes.StampPrefilled,
    prefilledKeys: prefilledData,
    prefillType: PrefillType.CompanyInfo
  });
};
export const handleBreezeCompanyInfoError = (error, dispatch, getState) => {
  dispatch(Tracker.signupInteraction(`breeze-industry-prefill-${error.status}`));
  dispatch(Tracker.signupInteraction(`breeze-company-info-prefill-error-${error.status}`));
  const email = getSignupDataValue(getState(), Data.Email);
  const domainFromEmail = getDomainFromEmail(email);
  LOG.error('Breeze Company Information Request Failed', {
    extra: Object.assign({}, getSentryExtra(error), {
      domainFromEmail
    })
  });
};