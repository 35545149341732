import styled from 'styled-components';
import { OAuthToButtonBgColor, OAuthToButtonBorderColor, OAuthToButtonFontColor, OAuthToButtonHoverColor } from './OAuthMapping';
import { GYPSUM } from 'HubStyleTokens/colors';
export const StyledOAuthContainer = styled.div.withConfig({
  displayName: "OAuthButtonstyles__StyledOAuthContainer",
  componentId: "s3vtc6-0"
})(["height:36px;max-width:420px;display:flex;align-items:center;justify-content:center;margin-right:auto;margin-left:auto;padding:2px 1rem;width:100%;margin-top:24px;"]);
export const StyledOAuthButton = styled.button.withConfig({
  displayName: "OAuthButtonstyles__StyledOAuthButton",
  componentId: "s3vtc6-1"
})(["width:100%;min-height:36px;display:flex;border-radius:4px;background:", ";border:2px solid ", ";color:", ";&:disabled{background-color:#7c98b6 !important;border-color:#7c98b6 !important;color:", " !important;cursor:not-allowed;div{background-color:#7c98b6 !important;border-color:#7c98b6 !important;}}&:hover{background-color:", ";div{background-color:", ";}}"], props => OAuthToButtonBgColor[props.oAuth], props => OAuthToButtonBorderColor[props.oAuth], props => OAuthToButtonFontColor[props.oAuth], GYPSUM, props => OAuthToButtonHoverColor[props.oAuth], props => OAuthToButtonHoverColor[props.oAuth]);
export const StyledOAuthImage = styled.div.withConfig({
  displayName: "OAuthButtonstyles__StyledOAuthImage",
  componentId: "s3vtc6-2"
})(["height:36px;width:36px;display:flex;justify-content:center;align-items:center;min-width:36px;img{height:auto;width:18px;}"]);
export const StyledOAuthText = styled.div.withConfig({
  displayName: "OAuthButtonstyles__StyledOAuthText",
  componentId: "s3vtc6-3"
})(["display:flex;justify-content:center;align-items:center;width:100%;height:36px;font-size:14px;letter-spacing:0.25px;font-weight:500;min-width:160px;"]);
export const StyledOAuthErrorMessage = styled.div.withConfig({
  displayName: "OAuthButtonstyles__StyledOAuthErrorMessage",
  componentId: "s3vtc6-4"
})(["font-size:12px;text-align:center;margin-top:10px;color:#425b76;"]);