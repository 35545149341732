import { Step } from 'signup-constants/Step';
import { ExperimentKeys } from '../../experiments/ExperimentKeys';
import { ExperimentParameterGroup } from '../../experiments/ExperimentParameterGroup';
import { getIsActiveAndAssignedToVariant } from '../../store/experiments/selectors';
import { setFlow } from '../../store/flow/action-creators';
import { getFlowSteps } from '../../flow-initial/getFlowConfig';
import { isPreVerification } from '../../flow-settings/settingsSelectors';
export const BranchACQ0167 = {
  name: 'BranchACQ0167',
  stateCheck: state => {
    return getIsActiveAndAssignedToVariant(state, ExperimentKeys.ACQ0167, [ExperimentParameterGroup.Variant_A, ExperimentParameterGroup.Variant_B]);
  },
  rebuildFlow: (flow, state, dispatch) => {
    const {
      preVerification,
      postExistingAccountSelection,
      postAccountCreation
    } = getFlowSteps();
    const postVerificationSteps = [Step.Password, Step.Name, Step.CompanyDomain, Step.Industry, Step.CompanySize, Step.ManagementLevel, Step.CompanyName];
    const nextSteps = [...(isPreVerification() ? preVerification : []), ...postVerificationSteps, ...postAccountCreation];
    dispatch(setFlow(nextSteps, preVerification, postVerificationSteps, postAccountCreation, postExistingAccountSelection));
    return nextSteps;
  },
  processed: false,
  processedOnStep: null,
  afterData: null,
  afterStep: null
};