import { useMemo } from 'react';
import { useSignupEmbedder } from 'signup-embedder/hooks/useSignupEmbedder';
import { useCurrentStep } from '../hooks/useCurrentStep';
import { useIsWideStep } from '../hooks/useIsWideStep';
import { useResponsiveness } from '../responsiveness/ResponsivenessProvider';
import { Data } from 'signup-constants/signupData/Data';
import { useSignupData } from '../signup-data/useSignupData';
import { getMessageComponent } from './getMessageComponent';
import { getMessageKey } from './getMessageKey';
const useMessageKey = () => {
  const {
    currentStep
  } = useCurrentStep();
  const {
    data: industry
  } = useSignupData(Data.Industry);
  const {
    isMobile
  } = useResponsiveness();
  return {
    messageKey: getMessageKey({
      currentStep,
      industry,
      isMobile
    })
  };
};
export const useMessage = () => {
  const {
    isWideStep
  } = useIsWideStep();
  const {
    shouldShowMessaging
  } = useSignupEmbedder();
  const {
    messageKey
  } = useMessageKey();
  const {
    isActive: isInstanceOfSignupEmbedder
  } = useSignupEmbedder();
  const MessageComponent = useMemo(() => {
    if (messageKey) {
      return getMessageComponent({
        messageKey
      });
    }
    return null;
  },
  // Do not re-render when just the industry is changing and not the step: that's when the user is typing.
  // We can change this once we make it a dropdown.
  [messageKey]);
  const noMessagingDisplayed = isInstanceOfSignupEmbedder && !shouldShowMessaging || isWideStep || !messageKey;
  const shouldDisplayMessaging = !noMessagingDisplayed;
  return {
    component: MessageComponent,
    shouldDisplayMessaging: shouldDisplayMessaging && MessageComponent
  };
};