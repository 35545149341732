import { isPostVerification } from '../flow-settings/settingsSelectors';
import { EndpointKey } from '../http/EndpointKey';
import { getIsPostVerification } from '../routing/getIsPostVerification';
import { getVerificationType } from '../store/app/selectors';
import { isEndpointStatusSuccess } from '../store/http-responses/selectors';
import { VerificationType } from './VerificationType';
const checkIsVerificationType = (state, verificationType) => getVerificationType(state) === verificationType;
const checkIsntVerificationType = (state, verificationType) => getVerificationType(state) !== verificationType;
export const isntGoogleSignup = state => checkIsntVerificationType(state, VerificationType.Google) || checkIsntVerificationType(state, VerificationType.GooglePrompt);
export const isntExistingUserExistingAccount = state => checkIsntVerificationType(state, VerificationType.ExistingUserExistingAccount);
export const isntExistingUser = state => checkIsntVerificationType(state, VerificationType.ExistingUser);
export const isExistingUserExistingAccount = state => checkIsVerificationType(state, VerificationType.ExistingUserExistingAccount);
export const isEmailVerificationSignup = state => checkIsVerificationType(state, VerificationType.Email);
export const isCodeVerificationSignup = state => checkIsVerificationType(state, VerificationType.EmailByCode);
export const isGoogleSignup = state => checkIsVerificationType(state, VerificationType.Google) || checkIsVerificationType(state, VerificationType.GooglePrompt);
export const isAppleSignup = state => checkIsVerificationType(state, VerificationType.Apple);
export const isMicrosoftSignup = state => checkIsVerificationType(state, VerificationType.Microsoft);
export const isOAuthSignup = state => isGoogleSignup(state) || isMicrosoftSignup(state) || isAppleSignup(state);
export const isntOAuthSignup = state => !isGoogleSignup(state) && !isMicrosoftSignup(state) && !isAppleSignup(state);
export const isExistingUser = state => checkIsVerificationType(state, VerificationType.ExistingUser);
export const isEmailVerificationSignupAndNotPostVerification = state => {
  const isEmailVerification = isEmailVerificationSignup(state) || isCodeVerificationSignup(state);
  return isEmailVerification && !getIsPostVerification();
};
export const getHasVerifiedIdentity = state => isPostVerification() || isOAuthSignup(state) || isExistingUser(state) || isEndpointStatusSuccess(EndpointKey.CompleteVerificationByCode)(state);
export const canUseExistingUserSignupEndpoint = state => isExistingUser(state);