export let Step;
(function (Step) {
  Step["CompanyDomain"] = "COMPANY_DOMAIN";
  Step["CompanyName"] = "COMPANY_NAME";
  Step["CompanySize"] = "COMPANY_SIZE";
  Step["CrmExperienceLevel"] = "CRM_EXPERIENCE_LEVEL";
  Step["Email"] = "EMAIL";
  Step["Industry"] = "INDUSTRY";
  Step["JobField"] = "JOB_FIELD";
  Step["JobRole"] = "JOB_ROLE";
  Step["Password"] = "PASSWORD";
  Step["Verification"] = "VERIFICATION";
  Step["CodeVerification"] = "CODE_VERIFICATION";
  Step["HubletSelection"] = "HUBLET_SELECTION";
  Step["Country"] = "COUNTRY";
  Step["PhoneNumber"] = "PHONE_NUMBER";
  Step["OptionalPhoneNumber"] = "OPTIONAL_PHONE_NUMBER";
  Step["EmailVerificationNotFound"] = "EMAIL_VERIFICATION_NOT_FOUND";
  Step["InternalError"] = "INTERNAL_ERROR";
  Step["LoginFailed"] = "LOGIN_FAILED";
  Step["StandardCreationError"] = "STANDARD_CREATION_ERROR";
  Step["VerifyFirstCreationError"] = "VERIFY_FIRST_CREATION_ERROR";
  Step["RateLimitedCreationError"] = "RATE_LIMITED_CREATION_ERROR";
  Step["RateLimitedEmailVerification"] = "RATE_LIMITED_EMAIL_VERIFICATION";
  Step["OAuthExistingLoginFailed"] = "OAUTH_EXISTING_LOGIN_FAILED";
  Step["OAuthPendingEmailVerification"] = "OAUTH_PENDING_EMAIL_VERIFICATION";
  Step["EmailVerificationFailed"] = "EMAIL_VERIFICATION_FAILED";
  Step["SessionExpired"] = "SESSION_EXPIRED";
  Step["ExistingAccountsFetchError"] = "EXISTING_ACCOUNTS_FETCH_ERROR";
  Step["RecaptchaCreationError"] = "RECAPTCHA_CREATION_ERROR";
  Step["PartnerExistingAccountError"] = "PARTNER_EXISTING_ACCOUNT_ERROR";
  Step["AlreadyAPartner"] = "ALREADY_A_PARTNER";
  Step["WordpressAuthorizationError"] = "WORDPRESS_AUTHORIZATION_ERROR";
  Step["MissingBlogIdeasGeneratorScopesError"] = "MISSING_BLOG_IDEAS_GENERATOR_SCOPES_ERROR";
  Step["ExistingUser"] = "EXISTING_USER";
  Step["ExistingAccounts"] = "EXISTING_ACCOUNTS";
  Step["LandingPage"] = "LANDING_PAGE";
  Step["ResendEmail"] = "RESEND_EMAIL";
  Step["MobileAppPrompt"] = "MOBILE_APP_PROMPT";
  Step["HublessNoAccount"] = "HUBLESS_NO_ACCOUNT";
  Step["IntegrationsAccountCreated"] = "INTEGRATIONS_ACCOUNT_CREATED";
  Step["WordpressAuthorization"] = "WORDPRESS_AUTHORIZATION";
  Step["WordpressConnectRedirect"] = "WORDPRESS_CONNECT_REDIRECT";
  Step["AcademyEmbeddedExistingUser"] = "ACADEMY_EMBEDDED_EXISTING_USER";
  Step["BlankScreenLoadingError"] = "BLANK_SCREEN_LOADING_ERROR";
  Step["CmsExistingUser"] = "CMS_EXISTING_USER";
  Step["EmbeddedSignupContinueScreen"] = "EMBEDDED_SIGNUP_CONTINUE_SCREEN";
  Step["Name"] = "NAME";
  Step["InstallSalesExtension"] = "INSTALL_SALES_EXTENSION";
  Step["CmsSignupIntent"] = "CMS_SIGNUP_INTENT";
  Step["CmsBusinessMaturity"] = "CMS_BUSINESS_MATURITY";
  Step["RecaptchaMissingInformationError"] = "RECAPTCHA_MISSING_INFORMATION_ERROR";
  Step["ManagementLevel"] = "MANAGEMENT_LEVEL";
  Step["ProblemAwareness"] = "PROBLEM_AWARENESS";
  Step["ProductExperience"] = "PRODUCT_EXPERIENCE";
  Step["IntegratedAppAuthorizationError"] = "INTEGRATED_APP_AUTHORIZATION_ERROR";
  Step["IntegratedAppAuthorization"] = "INTEGRATED_APP_AUTHORIZATION";
  Step["IntegratedAppConnectRedirect"] = "INTEGRATED_APP_CONNECT_REDIRECT";
})(Step || (Step = {}));