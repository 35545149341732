import styled from 'foundations-theming/utils';
import { devices } from '../../styles/viewports.styles';
export const StyledWave = styled.div.withConfig({
  displayName: "Wavestyles__StyledWave",
  componentId: "sc-1x8thua-0"
})([".Wave{display:none;}@media ", "{.Wave{display:block;position:fixed;bottom:0;left:0;width:100%;max-height:35%;z-index:-1;isolation:isolate;}.Wave__Wrapper{svg{display:initial;}}path{transition:all 20s ease;}.YellowWave{height:55%;max-height:70%;background:", ";}.LowYellowWave{height:20%;max-height:20%;background:", ";}.ErrorWave{background:", ";}.LowOrangeWave{background:", ";max-height:23%;}}"], devices.desktop, ({
  theme
}) => theme.color['do-not-use-marigold-light'], ({
  theme
}) => theme.color['do-not-use-marigold-light'], ({
  theme
}) => theme.color['do-not-use-thunderdome-light'], ({
  theme
}) => theme.color['do-not-use-sorbet-light']);