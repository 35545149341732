import { Data } from 'signup-constants/signupData/Data';
import { companyInfo } from '../http-company-info/CompanyInfoClient';
import { handleCompanyInfoError, handleCompanyInfoSuccess } from '../http-company-info/handleCompanyInfoResponse';
import { getIsSuccessfullyVerified, getVerificationType } from '../store/app/selectors';
import { isStepInNextOrCurrentStep } from '../store/flow/selectors';
import { VisitorContextActionTypes } from '../store/visitor-context/action-types';
import { Step } from 'signup-constants/Step';
import { fetchPublicVisitorContext } from '../visitor-context-fetch/fetchPublicVisitorContext';
import { handleVisitorContextSuccess } from '../visitor-context-fetch/handleVisitorContextResponse';
import { EndpointKey } from './EndpointKey';
import { FlowActionTypes } from '../store/flow/action-types';
import { hasEndpointBeenFetched } from '../store/http-responses/selectors';
import { getIsActiveAndAssignedToVariant } from '../store/experiments/selectors';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { ExperimentParameterGroup } from '../experiments/ExperimentParameterGroup';
import { getBreezeCompanyInfo } from '../http-breeze-company-info/BreezeCompanyInfoClient';
import { handleBreezeCompanyInfoError, handleBreezeCompanyInfoSuccess } from '../http-breeze-company-info/handleBreezeCompanyInfoResponse';

// NOTE: This object was wrapped in a function to avoid problematic cycles when frontend-infra-core migrates to Webpack 5
export const getEndpointsPrefilling = () => ({
  [EndpointKey.CompanyInfo]: {
    getRequestData: () => [Data.Email],
    handleError: handleCompanyInfoError,
    handleSuccess: handleCompanyInfoSuccess,
    method: companyInfo,
    stateCheck: state => {
      const isACQ0167VariantB = getIsActiveAndAssignedToVariant(state, ExperimentKeys.ACQ0167, [ExperimentParameterGroup.Variant_B]);

      // Don't invoke the original endpoint for variant B.
      if (isACQ0167VariantB) {
        return false;
      }
      const isVerificationTypeSet = Boolean(getVerificationType(state));
      return isVerificationTypeSet && isStepInNextOrCurrentStep(state, [Step.CompanyDomain, Step.CompanyName, Step.Industry]);
    }
  },
  [EndpointKey.VisitorContextFetch]: {
    handleSuccess: handleVisitorContextSuccess,
    method: fetchPublicVisitorContext,
    reduxActionType: VisitorContextActionTypes.Fetch
  },
  [EndpointKey.BreezeCompanyInfo]: {
    getRequestData: () => [Data.Email],
    handleError: handleBreezeCompanyInfoError,
    handleSuccess: handleBreezeCompanyInfoSuccess,
    method: getBreezeCompanyInfo,
    reduxActionType: FlowActionTypes.GoNextStep,
    once: true,
    stateCheck: state => {
      const endpointHasBeenFetched = hasEndpointBeenFetched(state, EndpointKey.BreezeCompanyInfo);
      if (endpointHasBeenFetched) {
        return false;
      }
      const isACQ0167VariantB = getIsActiveAndAssignedToVariant(state, ExperimentKeys.ACQ0167, [ExperimentParameterGroup.Variant_B]);

      // Only invoke this endpoint for variant B.
      if (!isACQ0167VariantB) {
        return false;
      }
      return getIsSuccessfullyVerified(state);
    }
  }
});