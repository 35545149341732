import { CompanySizeNumber } from 'signup-constants/signupData/CompanySizeNumber';
import { CompanySizeAttributes } from 'signup-constants/signupData/CompanySizeAttributes';
import { CompanySizeOption } from 'signup-constants/signupData/CompanySizeOption';
import { Tracker } from '../store/tracking/action-creators';
import { storePredictedCompanySize } from '../store/predictions/action-creators';
export const getCompanySizeOptionFromEmployeesRange = employeesRange => {
  if (!employeesRange) {
    return;
  }
  const {
    min,
    max
  } = employeesRange;
  if (min >= CompanySizeNumber.TenThousandOneOrMore) {
    return CompanySizeOption.TenThousandOneOrMore;
  }
  const companySizeOptionToPrefill = Object.keys(CompanySizeAttributes).find(companySizeOption => {
    const {
      companySizeRange
    } = CompanySizeAttributes[companySizeOption];
    return min >= companySizeRange.min && max <= companySizeRange.max;
  });
  return companySizeOptionToPrefill;
};
export const handleCompanySizeUpdate = (employeesRange, dispatch) => {
  const companySizeOption = getCompanySizeOptionFromEmployeesRange(employeesRange);
  if (!companySizeOption) {
    dispatch(Tracker.signupInteraction('company-size-prefill-empty'));
    return;
  }
  dispatch(Tracker.signupInteraction('company-size-prefill-success', {
    selectedOption: [companySizeOption]
  }));
  dispatch(storePredictedCompanySize(companySizeOption));
};